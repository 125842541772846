var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-light"},[_vm._m(0),_c('div',{staticClass:"containers"},[_vm._m(1),_c('div',{staticClass:"titleList"},_vm._l((_vm.titleList),function(item){return _c('div',{key:item.id,staticClass:"titleItem",on:{"click":function($event){return _vm.toDetail(item)}}},[_c('img',{attrs:{"src":item.imgaddr,"alt":""}}),_c('p',{staticStyle:{"font-size":"22px","color":"#333232"}},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.comm))]),_c('p',[_vm._v(_vm._s(item.wh))])])}),0),_c('img',{staticStyle:{"margin-top":"45px"},attrs:{"src":require("@imgs/ckgdbg.png"),"width":"100px","height":"20px","alt":""},on:{"click":function($event){return _vm.goRouter('/productcenter')}}})]),_c('div',{staticClass:"container1"},[_vm._m(2),_vm._m(3),_c('img',{staticStyle:{"margin-top":"45px"},attrs:{"src":require("@imgs/ckgdbg.png"),"width":"100px","height":"20px","alt":""},on:{"click":function($event){return _vm.goRouter('/companyprofile')}}})]),_c('div',{staticClass:"container2"},[_vm._m(4),_c('div',{staticClass:"news"},_vm._l((2),function(v,i){return _c('div',{key:i,staticClass:"newsItem"},[_c('div',{staticClass:"newstitle"},[_vm._v("四川将实行行政村快递全覆盖")]),_vm._m(5,true)])}),0),_c('img',{attrs:{"src":require("@imgs/ckgdbg.png"),"width":"100px","height":"20px","alt":""},on:{"click":function($event){return _vm.goRouter('/newscenter')}}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg1"},[_c('img',{staticClass:"banner",attrs:{"src":require("../assets/banner.png"),"alt":""}}),_c('div',{staticClass:"text-pos wid"},[_c('div',{staticClass:"container3 text wid"},[_c('div',{staticClass:"first"},[_vm._v("做好服务，携手共赢")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@imgs/productTitle.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@imgs/gsjj.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('p',[_vm._v("四川名优世汇药业有限公司成立于2010年7月，位于成都市金牛区，公司主营业务包括医药批发、B2B电商，主要经营厂家代理品种、OTC产品、医疗器械、中药饮片、中药材、预包装食品等")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("@imgs/xwzx.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"newscontent"},[_vm._v(" 本报四川6月3日电 （记者邵玉姿）为有效解决农村快递不按址投递问题，彻底解决农村末端服务违规收费问题，近日，四川邮政管理局印发《关于加快推进“快递进村”工作的指导意见》，提出到2021年8月份行政村快递服务基本实现全覆盖。"),_c('br'),_vm._v(" 《意见》明确了三项重点任务。一是因地制宜推进“快递进村”，在全面摸清辖区进村底数的基础上，根据各地实际细化实化“快递进村”推进方案，强力推进企业合作进村。二是开展农村快递专项整治活动，依法依规审查企业农村快递服务能力，切实推动农村快递服务规范化建设，保障农村网点稳定，夯实农村寄递安全基础。三是压实“快递进村”统计数据，采取多种形式开展数据核查，接受社会群众监督，优化数据报送渠道，确保采集的数据资源真实、准确、完整和及时。 ")])
}]

export { render, staticRenderFns }