<template>
  <div class="bg-light">
    <div class="bg1">
      <img src="../assets/banner.png" alt="" class="banner" />
      <div class="text-pos wid">
        <div class="container3 text wid">
          <div class="first">做好服务，携手共赢</div>
          <!-- <div class="senced" >Do a good job in service and win together</div> -->
        </div>
      </div>
    </div>
    <div class="containers">
      <div class="title">
        <img src="~@imgs/productTitle.png" alt="" />
      </div>
      <div class="titleList">
        <div class="titleItem" v-for="item in titleList" :key="item.id" @click="toDetail(item)">
          <img :src="item.imgaddr" alt="" />
          <p style="font-size: 22px; color: #333232">{{ item.title }}</p>
          <p>{{ item.comm }}</p>
          <p>{{ item.wh }}</p>
        </div>
      </div>
      <img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" style="margin-top: 45px"
        @click="goRouter('/productcenter')" />
    </div>
    <div class="container1">
      <div class="title">
        <img src="~@imgs/gsjj.png" alt="" />
      </div>
      <div class="content">
        <p>四川名优世汇药业有限公司成立于2010年7月，位于成都市金牛区，公司主营业务包括医药批发、B2B电商，主要经营厂家代理品种、OTC产品、医疗器械、中药饮片、中药材、预包装食品等</p>
      </div>
      <img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" style="margin-top: 45px"
        @click="goRouter('/companyprofile')" />
    </div>
    <div class="container2">
      <div class="title">
        <img src="~@imgs/xwzx.png" alt="" />
      </div>
      <div class="news">
        <div class="newsItem" v-for="(v, i) in 2" :key="i">
          <div class="newstitle">四川将实行行政村快递全覆盖</div>
          <div class="newscontent">
            本报四川6月3日电
            （记者邵玉姿）为有效解决农村快递不按址投递问题，彻底解决农村末端服务违规收费问题，近日，四川邮政管理局印发《关于加快推进“快递进村”工作的指导意见》，提出到2021年8月份行政村快递服务基本实现全覆盖。<br />
            《意见》明确了三项重点任务。一是因地制宜推进“快递进村”，在全面摸清辖区进村底数的基础上，根据各地实际细化实化“快递进村”推进方案，强力推进企业合作进村。二是开展农村快递专项整治活动，依法依规审查企业农村快递服务能力，切实推动农村快递服务规范化建设，保障农村网点稳定，夯实农村寄递安全基础。三是压实“快递进村”统计数据，采取多种形式开展数据核查，接受社会群众监督，优化数据报送渠道，确保采集的数据资源真实、准确、完整和及时。
          </div>
        </div>
        <!-- <div class="newsItem">
          <div class="newstitle">广东将实行行政村快递全覆盖</div>
          <div class="newscontent">
            1969年1月，不满16岁的习近平来到陕北梁家河村插队，在这里度过了七年知青岁月。在村民们印象中，习近平当年住过的窑洞里，炕沿上、铺盖上、枕头边上，全都是书。
            “现在回忆起来，近平他们住的那个窑洞特别温暖，也特别神奇，就像是知识的宝库。”和习近平一起劳动、生活过的村民武晖回忆。
            那时候，武晖很想看看那些书里写的都是什么，但一开始不敢多翻。倒是习近平首先开口，喊着武晖小名说：“铁锁，你喜欢看什么书，就拿去看。”遇到不懂的，武晖就问习近平，“无论问什么，近平都很耐心地教给我”。
            科普读物《十万个为什么》、史书《三国志》、高尔基的《母亲》、肖洛霍夫的《静静的顿河》……武晖对知识的渴望触动了习近平，他琢磨着做点什么，帮乡亲们学点文化。
          </div>
        </div> -->
      </div>
      <img src="~@imgs/ckgdbg.png" width="100px" height="20px" alt="" @click="goRouter('/newscenter')" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      titleList: [
        {
          id: 1,
          imgaddr: require("@imgs/1.png"),
          title: "感冒灵颗粒(999)",
          comm: "华润三九医药股份有限公司",
          spec: "10g*9袋",
          wh: "【批准文号】国药准字Z44021940",
        },
        {
          id: 2,
          imgaddr: require("@imgs/2.png"),
          title: "藿香正气水",
          comm: "四川天府康达药业集团府庆制药有限公司",
          spec: "10ml*10支",
          wh: "【批准文号】国药准字Z51022378",
        },
        {
          id: 3,
          imgaddr: require("@imgs/3.png"),
          title: "小柴胡颗粒(有糖)",
          comm: "广州白云山光华制药股份有限公司",
          spec: "10g*10袋",
          wh: "【批准文号】国药准字Z44020211",
        },
      ],
    };
  },
  methods: {
    goRouter(value) {
      this.$router.push(value);
    },
    toDetail(item) {
      this.$router.push({
        path: "/goodDetail?id=" + item.id,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.containers {
  margin-top: 75px;

  .title {
    margin: 20px 0 60px 0;
  }

  .titleList {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .titleItem {
      width: 300px;
      background: #ffffff;
      border: 1px solid #cccccc;
      margin: 0 20px;
      padding-top: 30px;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 220px;
      }
    }
  }
}

.container1 {
  width: 100%;
  height: 700px;
  background: #f3f3f3;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 48px;

  .title {
    margin-top: 75px;
  }

  .content {
    width: 1198px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: #666666;
    margin-top: 60px;
    text-align: left;
  }
}

.container3 {
  margin: auto;
  padding: 0 15px;
}

.container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;

  .title {
    // margin-top: 30px;
  }

  .news {
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Microsoft YaHei;

    .newsItem {
      width: 561px;
      text-align: left;
      font-size: 18px;
      color: #666565;
      line-height: 30px;
      margin: 30px;

      .newstitle {
        font-size: 22px;
        font-weight: 400;
        color: #333232;
        line-height: 30px;
      }
    }
  }
}

.banner {
  width: 100%;
  height: 633px;
  margin-bottom: 7px;
}

.bg1 {
  position: relative;
}

.text-pos {
  position: absolute;
  top: 23%;
  left: 20%;
  color: brown !important;
  display: flex;
  justify-content: flex-start;
}

.text {
  text-align: left;
}

.first {
  font-size: 50px;
  font-family: Alegreya Sans SC ExtraBold;
  color: #fcfafc;
  animation: myfirst 1.5s linear;
}

.senced {
  font-size: 50px;
  font-family: Alegreya Sans SC ExtraBold;
  color: #fcfafc;
  animation: mysenced 1.5s linear;
}

.text-info {
  font-size: 32px;
  color: #17a2b8;
}

.center {
  text-align: center;
}

.m-bottom {
  margin-bottom: 21px;
}

.con-info {
  line-height: 2;
  text-align: left;
  font-size: 14px;
  text-indent: 28px;
}

@keyframes myfirst {
  0% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes mysenced {
  0% {
    transform: translateY(-63px);
    opacity: 0;
  }

  80% {
    opacity: 0.5;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}</style>